#container {
    position: relative;
    min-height: 600px;
    min-width: 600px;
    height: max-content;
    width: max-content;
}

#tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    z-index: 0; /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
}