.footerContainer{

}
.footerWrapper{
    margin-right: 30px;
}
.footerWrapper:last-child{
    margin-right: 0px;
}
@media screen and (max-width: 600px) {
    .footerContainer{
        flex-wrap: wrap;
        justify-content: center;
    }
    .footerWrapper{
        width: 50%;
        margin-right: 0px;
        margin-bottom: 30px;
    }
  }