.featureRowOneColOne{
    display: flex;
    align-items: center;
    width: 50%;
}
.featureRowOneColTwo{
    display: flex;
    align-items: center;
    width: 50%;
    transform: translateY(20%);
}
.featureRowTwoColOne{
    display: flex;
    align-items: center;
    width: 50%;
    transform: translateY(-20%);
}
.trackingAndAnalyticsContainer{
    display: flex;
    align-items: center;
    width: 100%;
}
/* 
.featureHeading{
    font-size: 26px;    
    font-weight: 900;
    font-family: AILERON LIGHT;
} */
/* .featureSubHeading{
    font-size: 18px; 
    margin-top: 10px;  
    color: black;
    font-family: "AILERON SEMIBOLD ITALIC";
} */
