.solutionPageContainer{
    padding:2%;
    padding-left:3%;
    padding-right:3%;
    min-height: 100vh;
    padding-top: 75px;
    background-color: rgb(255, 255, 255);
}

.solutionHeading{
    font-family: "Anton";
    font-size: 40px;
    display: flex;
    letter-spacing: 2px;
    font-weight: 800;
    justify-content: center;
    text-transform: uppercase;
}

.solutionSubHeading{
    font-family: "AILERON HEAVY";
    font-size: 22px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.solutionsContainer{
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
}

.solutionWrapper{
    background: white;
    min-height: 450px;
    width: 28%;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    align-items: center;
    transition: all 0.1s linear;
    border: 1px solid orange;
    box-shadow: 0px 10px 10px 1px orange;
}

.solutionWrapper:hover{
    transform: scale(1.03);
    transition: all 0.1s linear;
    box-shadow: 0px 30px 15px 1px orange;
}

.solutionText{
    text-align: center;
    padding: 20px;
    padding: 20px;
}

.particularSolutionHeading{
    font-size: 22px;
    font-weight: 800;
}

@media screen and (max-width: 600px) {
    .solutionHeading{
        font-size: 28px;
        text-align: center;
    }
    .solutionSubHeading{
        font-size: 18px;
        text-align: justify;
        padding-left: 5%;
        padding-right: 5%;
    }
    .solutionsContainer{
        flex-wrap: wrap;
    }
    .solutionWrapper{
        align-items: center;
        justify-content: center;
        width: 80%;
        margin-top: 60px;
        box-shadow: 0px 0px 10px 1px orange;
    }
    .solutionWrapper:first-child{
        margin-top: 20px;
    }

    .solutionWrapper:last-child{
        margin-bottom: 30px;
    }

    .solutionWrapper:hover{
        transform: scale(1.1);
        transition: all 0.1s linear;
        box-shadow: 0px 0px 20px 1px orange;
    }

    .particularSolutionHeading{
        font-size: 22px;    
        font-weight: 900;
    }

}