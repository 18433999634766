.about-us {
    padding: 5rem;
    /* background-color: #f9f9f9; */
  }
  
  .about-us__header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .about-us__features {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .feature-card {
    background: #fff;
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem;
    text-align: center;
    box-shadow: 1px 1px 3px 3px #ecb397;
    width: 250px;
  }
  
  .feature-card__icon {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .feature-card__title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .feature-card__description {
    font-size: 1rem;
    color: #666;
  }
  
  .feature-card__icon img {
    width: 100px;
    height: 100px;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 50px;
  }

  h3 {
    color: #e27741;
  }