.wrapper{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.homeContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    padding-top: 70px;
    margin-left: 5%;
    margin-right:5%;
    margin-top:5%;
}

.homeLeftWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    z-index: 3;

}
.hashtagLine{
    font-family: 'AILERON HEAVY';
    margin-top: 0px;
    margin-bottom: 28px;
    font-size: 28px;
    font-weight: 800;
}
.mainHomeHeading{
    font-size: 62px;
    font-family: 'Anton';
    margin-top: -20px;
}
.mainHomeSubHeading{
    font-size: 20px;
    margin-top: 20px;
    font-weight: 800;
    color: rgb(100,100,100);
    font-family: 'AILERON HEAVY';
}
.homeRightWrapper{
    width: 50%;
    margin-top: -10%;
    z-index: 2;
}

.getStartedContainer{
    display: flex;
    justify-content: flex-start;
}

.btn{
    position: relative;
    display: block;
    color: rgb(255, 255, 255);
    width: max-content;
    background-color: orange;
    font-size: 15px;
    font-family: "montserrat";
    text-decoration: none;
    margin: 30px 0;
    font-weight: 600;
    border: 2px solid orange;
    padding: 10px;
    text-transform: uppercase;
    overflow: hidden;
    transition: 1s all ease;
    cursor: pointer;
  }
.btn::before{
    background: orange;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: -1;
    transition: all 0.6s ease;
}
.btn4::before{
    width: 100%;
    height: 0%;
    transform: translate(-50%,-50%) rotate(-45deg);
}
.btn4:hover{
    color:white
}
.btn4:hover::before{
    height: 380%;
}
.homepageGif{
    width:100%
}

@media screen and (max-width: 600px) {
    .wrapper{
        min-height: calc(100vh);
    }
    .homeContainer{
        flex-wrap: wrap-reverse;
    }
    .homeLeftWrapper{
        width: 100%;
    }
    .hashtagLine{
        font-size: 18px;
        text-align: start;
    }
    .mainHomeHeading{
        margin-top: -20px;
        text-align: start;
        font-size: 38px;
    }
    .mainHomeSubHeading{
        text-align: start;
        font-size: 14px;
    }
    .homeRightWrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 30px;
    }
    .homepageGif{
        width: 90%;
    }

    .getStartedContainer{
        justify-content: flex-start;
    }
}