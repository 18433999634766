.faqContainer{
    margin-top: 100px;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.searchFaqText{
    margin-top: 20px;
    min-width: 50vw;
    padding-left: 40px;
    height: 40px;
    font-size: 16px;
    border:1px solid rgb(200,200,200);
    outline: none;
}

.searchFaqText:hover{
    border:1px solid rgb(216, 162, 70);
    outline: none;
}

.searchFaqText:focus{
    border:1px solid rgb(255, 162, 0);
    outline: none;
}

.faqHeadingsContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 20px;
    max-height: max-content;
}
.faqHeadingsWrapper{
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center;     */
    height: 300px;
    width: 230px;
    cursor: pointer;
    margin: 10px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px 1px grey;
    /* color: grey; */
    font-size: 18px;
    border-radius: 2px;
    transition: all 0.2s linear;
}
.faqHeadingsWrapper:hover{
    color: orange;
    box-shadow: 0 10px 20px 1px grey;
}
.hidefaqHeadingsWrapper{
    transition: all 0.2s linear;
    height: 30px;
    /* margin: 0px; */
    /* width: 120px; */
    box-shadow: 0 0 10px 1px transparent;
}
.hidefaqHeadingsWrapper:hover{
    box-shadow: 0 10px 20px 1px transparent !important;
}
.faqHeadingImage{
    height:200px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    transition: all 0.2s linear;
}
.faqHeadingImageHide{
    transition: all 0.2s linear;
    transform: scaleY(0);
    opacity: 0;
}
.faqHeadingLabel{
    font-size: 19px;
    padding: 10px;
    font-family: Anton;
}
.faqHeadingLabelHide{
    position: absolute;
    text-transform: uppercase;
    /* margin-top: -115px; */
}
.faqHeadingDetail{
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
    color:black;
    font-size: 13px;
    transition: all 0.2s linear;
}
.faqHeadingDetailHide{
    transition: all 0.2s linear;
    transform: scaleY(0);
    opacity: 0;
}
.faqData{
    /* border-top: 1px solid orange; */
    padding-top: 20px;
    position: absolute;
    margin-bottom: 10px;
    min-width: 60vw;
    max-width: 60vw;
    transition: all 0.4s linear;
}
.hidefaqData{
    transform: scale(0);
    transition: all 0.4s linear;
}
.faqQuestionAnswerContainer{
    margin: 20px;

    border-left: 1px solid rgb(220,220,220);
    border-right: 1px solid rgb(220,220,220);
    border-bottom: 1px solid rgb(220,220,220);
}
.faqQuestionContainer{
    padding: 10px;
    width: 100%;
    font-size: 19px;
    border-radius: 10px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* box-shadow: 0 0 2px 1px grey; */
}

.faqAnswerContainer{
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    font-size: 17px;
    transition: all 0.3s ease-in-out;
}
.hidefaqAnswerContainer{
    font-size: 0;
    height: 0;
    transform: scaleY(0);
    transition: all 0.3s ease-in-out;
}