.featureContainer{
    background-color: rgb(255, 251, 246);
    padding-top: 70px;
    padding-bottom:2%;
    overflow: hidden;
    /* min-height: calc(100vh - 70px); */
}

.featureMainHeading{
    display: flex;
    justify-content: center;
    font-size: 40px;
    font-weight: 900;
    text-transform: uppercase;
    font-family: "Anton";
    letter-spacing: 2px;
}

.featurePageSubHeading{
    font-family: 'AILERON HEAVY';
    font-size: 22px;
    margin-top: 10px;
    text-align: center;
}
.particularFeatureWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 23%;
    padding-top: 20px;
    padding-bottom: 40px;
    background-color: white;
    box-shadow: 0 0 5px 1px orange;
    border-radius: 10px;
    transition: 0.2s all ease-in-out;
}

.particularFeatureWrapper:hover{
    transform: scale(1.1);
    transition: 0.2s all ease-in-out;
}
.featureLabel{
    display: flex;
    justify-content: center;
    padding-top: 5%;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    color: rgb(25,25,25);
}

.featureText{
    margin-top: 20px;
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}
.featureRowOne{
    display: flex;
}
.featureDetailTextLeftSide{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}

.featureDetailTextRightSide{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: right;
}
.featureRowOneColOne{
    display: flex;
    align-items: center;
    width: 50%;
}

.featureRowTwo{
    display: flex;
}
.featureRowOneColTwo{
    display: flex;
    align-items: center;
    width: 50%;
    transform: translateY(20%);
}
.featureRowTwoColOne{
    display: flex;
    align-items: center;
    width: 50%;
    transform: translateY(-20%);
}
.featureRowTwoColTwo{
    display: flex;
    align-items: center;
    width: 50%;
}
.trackingAndAnalyticsContainer{
    display: flex;
    align-items: center;
    width: 100%;
}

.featureHeading{
    font-size: 28px;    
    font-weight: 900;
}
.featureSubHeading{
    font-size: 18px; 
    margin-top: 10px;  
    color: black;
    font-family: "AILERON SEMIBOLD ITALIC";
}

.featurespath{
    fill : url(#gradient)
}


@media screen and (max-width: 600px) {
    .featureMainHeading{
        font-size: 28px;
        text-align: center;
    }
    .featurePageSubHeading{
        font-size: 18px;
        text-align: justify;
        padding-left: 5%;
        padding-right: 5%;
    }
    .dashboardImage{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .featureRowOne{
        flex-wrap: wrap;
    }
    .featureRowOneColOne{
        min-width: 100vw;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start !important;
        text-align: right;
    }
    .featureRowOneColTwo{
        min-width: 100vw;
        display: flex;
        justify-content: flex-start;
        text-align: left;
    }

    .featureRowTwo{
        flex-wrap: wrap;
        margin-top: 40%;
    }

    .featureRowTwoColOne{
        display: flex;
        justify-content: flex-end;
        min-width: 100vw;
        transform: translateY(0%) !important;
    }
    .featureRowTwoColTwo{
        margin-top: 80px;
        min-width: 100vw;
        display: flex;
        justify-content: flex-start;
    }

    .featureDetailTextLeftSide{
        padding-top: 380px;
        width: 100%;
        margin-left: 2%;
        margin-right: 30%;
        display: flex;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        text-align: left !important;
    }
    .featureDetailTextRightSide{
        width: 70%;
        padding-top: 380px;
        padding-bottom: 100px;
        margin-left: 10%;
        align-items: flex-end;
        text-align: right !important;
    }
    .leftSideSVG{
        position: absolute;
        /* margin-right: 22px; */
    }
    .rightSideSVG{
        position: absolute;
        margin-left: -45px;
    }
    .featureHeading{
        font-size: 22px;    
        font-weight: 900;
    }
    .featureSubHeading{
        font-size: 15px; 
        margin-top: 10px;  
        color: black;
        font-family: "AILERON SEMIBOLD ITALIC";
    }
}