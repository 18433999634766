.topbarContainer{
    height:70px;
    width: calc(100vw - 10%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    position: fixed;
    top: 70px;
    transition: top 0.3s ease-in-out;
    color: rgb(255, 255, 255);
    z-index: 100;
    margin-top: -70px;
    user-select: none;
    transition: all 0.2s ease-in;
}
.navItemActive{
    color:orange !important;
}
.closedtopbarMenu{
    display: flex;
}
.topbarLogo{
    height: 40px;
    width: 40px;
    background-color: red;
    border: 3px solid orange;
    transform: rotate(45deg);
    color:orange;
    background:radial-gradient(black,rgb(106, 69, 0));
    display: flex;
    align-items: center;
    justify-content: center;
}
.topbarLogoDetails{
    font-size: 18px;
    font-weight: 800;
    transform: rotate(-45deg);
}
.topbarNavigationMenu{
    display: flex;
    align-items: center;
    margin-left: 50px;
    justify-content: flex-end;
}
.topbarNavigationMenuTabs{
    margin-left: 35px;
    font-size: 18;
    font-weight: 600;
    letter-spacing: 0.02em;
    cursor: pointer;
    text-decoration: none;
    color: black;
}
.topbarNavigationMenuTabs:active{
    text-decoration: solid;
}
.topbarNavigationMenuTabs:first-child{
    margin-left: 0px;
}

/* CSS */
.button-87 {
  margin: 10px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-87:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.button-87:active {
  transform: scale(0.95);
}

.threeDotMenuButton{
    display: none;
    color: black;
    font-size: 30px;
    transform: rotate(270deg);
}

.topbarNavigationMobileMenu{
    min-width: 90%;
    top:75px;
    position: absolute;
    background-color: rgb(255, 255, 255);
    transition: 0.4s all linear;
    box-shadow:0 0 10px 1px rgb(115, 75, 0);
}
.topbarNavigationMobileMenuDisplayNone{
    display: none;
}

.comingsoonItem{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.comingsoonItemLabel{
    font-size: 10px;
    position: absolute;
    margin-top: 20px;
    font-weight: 400;
    color: orange;
}

@media screen and (max-width: 950px) {
    .topbarContainer{
        justify-content: flex-start;
    }
    .topbarNavigationMenu{
        display: none;
    }
    .topbarNavigationMenuTabs{
        margin-left: 0px;
    }
    .threeDotMenuButton{
        display: block;
    }
    .button-87{
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .topbarNavigationMobileMenu .topbarNavigationMenuTabs{
        padding: 10px;
    }
    .comingsoonItem{
        align-items: center;
        flex-direction: row;
        
    }
    .comingsoonItemLabel{
        padding-left: 5px;
        position: relative;
        margin-top: 0px;
    }
}
  