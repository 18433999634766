.helpcenterHeadingContainer{
    background: url('../../../img/helpcenter/container-bg.jpg');
    height: 400px;
    display: flex;
    margin-top: 70px;
    color: white;
    font-family: Anton;
    font-size: 40px;
    padding-left: 10%;
    padding-right: 10%;
    align-items: center;
}
.helpcenterArticlesContainer{
    display:flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: rgb(255, 251, 248);
}
.helpcenterCategoryContainer{
    padding: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 10px 10px 1px rgba(200, 200, 200, 0.257);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 300px;
    margin: 20px;
}
.helpcenterWhatsnew{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 4%;
    padding-bottom: 2%;
}
.helpcenterWhatsnewContainer{
    display: flex;
    align-items: center;
    overflow-x: auto;
}
.helpcenterWhatsNewWrapper{
    min-height: 270px;
    min-width: 300px;
    width: 300px;
    box-shadow: 0 10px 10px 1px rgba(128, 128, 128, 0.331);
    margin: 20px;
}
.helpcenterWhatsNewWrapper:first-child{
    margin-left: 10px;
}
.helpcenterWhatsNewCategoryWrapper{
    padding: 5px;
    background-color: orange;
    color: white;
    font-weight: 600;
    width: max-content;
    font-size: 12px;
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 15px;
    transition: all 1s ease-in-out;
}

.helpcenterTabContainer{
    padding-left: 5%;
    padding-right: 5%;
    width: 60%;
}
.helpcenterArticleWrapper{
    margin-top: 3%;
    padding: 20px;
    background-color: #ffffff;
    border-bottom: 1px solid rgb(220,220,220);
}
.helpcenterArticleWrapper:hover{
    background-color: rgb(253, 253, 253);
}