
.contactUsContainer {
    /* min-height: calc(100vh - 80px); */
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    background-color: rgb(255, 246, 237);
}
.contactUsFormWrapper{
  width: 50%;
}
.inputTextField{
    outline: none;
    border: none;
    width: 80%;
    margin-bottom: 15px;
    /* box-shadow: 0 0 2px 1px grey; */
    border: 1px solid grey;
    padding:10px;
    font-family: Arial, Helvetica, sans-serif;
}

.invalid{
  border: 1px solid red;
}



@media screen and (max-width: 600px) {
  .contactUsFormWrapper{
    width: 90%;
  }
}
  
  