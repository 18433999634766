html{scroll-behavior:smooth}


.flexRowAllCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}
.heading{
    font-family: "Anton";
}

.subHeading{
    font-family: "AILERON HEAVY";
}

.btn-disabled{
  background-color: transparent !important;
  border: none !important;
  color: grey !important;
  cursor: not-allowed !important;
}

@media screen and (max-width: 600px) {
    .topbarContainer.responsive {position: relative;}
    .topbarContainer.responsive .icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .topnav.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
}