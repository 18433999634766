.what-we-offer {
    text-align: center;
    padding: 20px;
    padding-bottom: 70px;
  }
  
  .sections {
    display: flex;
    flex-direction: column;
    gap: 80px;
  }
  
  .section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 120px;
    flex-wrap: wrap;
  }
  
  .reverse {
    flex-direction: row-reverse; /* This switches the layout for the middle section */
  }
  
  .card {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 1px 1px 3px 3px #ecb397;
    max-width: 650px;
    height : 250px;
    /* max-height: auto; */
    text-align: left;
  }
  
  .card h2 {
    margin-top: 10px;
    color: #e27741;
  }
  
  .card ul {
    padding-left: 30px;
  }
  
  .card li {
    margin-bottom: 20px;
    color: #555;
  }
  
  .image img {
    max-width: 450px;
    height: auto;
  }
  
  @media (max-width: 900px) {
    .section {
      flex-direction: column;
    }
    .reverse {
      flex-direction: column; /* Ensures mobile responsiveness */
    }
  }
  