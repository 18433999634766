.signupWrapper{
    height: 80vh;
    width: 80vw;
    background-color: white;
    position: absolute;
    box-shadow: 0 0 10px 1px rgb(157, 102, 0);
    border-radius: 15px;
    display: flex;
}

.signupWrapperLeftContainer{
    display: flex;
    background-color: rgb(237,122,57);
    width: 60%;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.leftcontainerWrapper{
    display: flex;
    height: 100%;
}
.awssld__wrapper{
    height: 95% !important;
}
.awssld__container{
    padding-bottom: 600px !important;
}
.awssld__controls__arrow-left, .awssld__controls__arrow-right{
    transform: scale(0.3);
}
.awssld__controls--active .awssld__controls__arrow-left{
    transform: scale(0.4) !important;
}

.awssld__controls--active .awssld__controls__arrow-right{
    transform: scale(0.4) !important;
}
.awssld__bullets{
    position: static !important;
    transform: scale(0.4) !important;
}
.awssld__timer{
    /* display: none !important; */
    background-color: transparent !important;
}
.awssld__bullets .awssld__bullets--active{
    background: rgb(111, 72, 0) !important;
}

.awssld__bullets button{
    background: rgb(255, 212, 132) !important;
}
.awssld__bullets--loading{
    background: rgb(111, 72, 0) !important;
}

.signupWrapperRightContainer{
    overflow: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 32%;
    padding-left: 4%;
    padding-right: 4%;
}

.heading{
    font-family: "EuclidCircularA","Open Sans";
}

.subHeading{
    font-family: "AILERON HEAVY";
}

.formData{
    display: flex;
    flex-direction: column;
}

.signupLabel{
    margin-top: 15px;
    font-weight: 200;
    font-size: 13px;
}

.inputSignUp{
    width: 100%;
    min-height: 30px;
    padding-left: 2%;
    padding-right: 2%;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(200,200,200);
}
.invalid{
    outline: none;
    border: 1px solid rgb(255, 149, 149) !important;
}
.inputSignUp:focus{
    border: 1px solid orange;
}

.inputSignUp:hover{
    border: 1px solid orange;
}
.signupPageLogo{
    width: 35%;
}

@media screen and (max-width: 600px) {
    .signupWrapper{
        width: 90vw;
    }
    .signupWrapperLeftContainer{
        display: none;
    }
    .signupWrapperRightContainer{
        width: 100%;
    }
    .inputSignUp{
        width: 95%;
    }
    .signupPageLogo{
        width: 50%;
    }
}