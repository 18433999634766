.pricingContainer{
    padding-top: 100px;
}

.pricingLabel{
    font-family: Anton;
    font-size: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
}

.pricingWrapper{
    margin-top: 40px;
    border-radius: 30px;
    border: 1px solid rgb(234, 234, 234);
    width: calc(31% - 80px);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 30px;
    padding-left: 40px;
    padding-right: 40px;
    min-width: 200px;

}

.pricingWrapper:nth-child(1){
    margin-right: 31%;
    background:linear-gradient(to bottom right,rgb(255, 249, 240),white);
}
.pricingWrapper:nth-child(2){
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0);
    box-shadow: 0 0 30px 2px rgba(81, 53, 0, 0.187);
    background:linear-gradient(to bottom right,white,white);
}

.pricingWrapper:nth-child(3){
    background:linear-gradient(to bottom left,rgb(255, 249, 240),white);
}

@media screen and (max-width: 600px) {
    .pricingWrapper{
        width: 75%;
    }
    .pricingWrapper:nth-child(1){
        margin-right: 0%;
        background:linear-gradient(to bottom right,rgb(255, 249, 240),white);
    }
    .pricingWrapper:nth-child(2){
        position: relative;
    }
}
.pricingPackageName{
    font-size: 34px;
    
    font-weight: 500;
}
.listValues{
    margin-bottom: 8px;
}


.priceButtonWrapper{
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%) scale(0.7);
}
  
.priceButtonLinkWrapper{
    position: relative;
    cursor: pointer;
    user-select: none;
}
  
.priceButtonLink{
    display: block;
    width: 250px;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    text-decoration: none;
    background: #333;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 3px solid #333;
    transition: all .35s;
}
  
.priceButtonIcon{
    width: 50px;
    height: 50px;
    border: 3px solid transparent;
    position: absolute;
    transform: rotate(45deg);
    right: 0;
    top: 0;
    z-index: -1;
    transition: all .35s;
}
  
.priceButtonIcon .priceButtonSvg{
    width: 30px;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    transform: rotate(-45deg);
    fill: orange;
    transition: all .35s;
}
  
.priceButtonLink:hover{
    width: 200px;
    border: 3px solid orange;
    background: transparent;
    color: orange;
}
  
.priceButtonLink:hover + .priceButtonIcon{
    border: 3px solid orange;
    right: -25%;
}


/* CSS */
.pricingCompareButton {
    margin: 10px;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    /* border-radius: 10px; */
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #ff822f 0%, #f6a430  51%, #ff822f  100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
  
.pricingCompareButton:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
  
.pricingCompareButton:active {
    transform: scale(0.90);
}

.pricingCompareTableHead{
    display:flex;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid rgb(240,240,240   );
    position: sticky;
    top:70px;
    z-index: 10;
    background-color: white;
    background:linear-gradient(to top left,white,rgb(254, 248, 237))
}
.pricingColumnHead{
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
}
.pricingCompareTableFirstColumn{
    width:35%
}
.pricingCompareTableNextColumns{
    width: 21.66%;
}
.pricingCompareTableValueHead{
    position: sticky;
    top:138px;
}
.pricingFeatureHeading{
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    background-color: white;
}
.pricingCompareTableValue{
    display: flex;
    align-items: center;
}
.pricingFeatureValue{

    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 17px;
}